import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { DataStore } from '@aws-amplify/datastore'
import { Post } from './models'
import{useHistory} from 'react-router-dom'

export default function PostList () {

  let history = useHistory()

  async function handleEdit(id){
    console.log('You Hit Edit Button for post ID:',id);
     //route to another page to acknowledge delete  
    history.push(`/editpost/${id}`)
  }

  async function handleRemove(id){
    console.log(id);
    //remove item
    const modelToDelete = await DataStore.query(Post, id);
    DataStore.delete(modelToDelete);

    //route to another page to acknowledge delete  
    history.push("/deleted")
  }

  const [posts, setPosts] = useState([])
  
  useEffect(() => {
    const getData = async () => {
      const models = await DataStore.query(Post)
      setPosts(models)
    }
    getData()
  }, [])

 


  return (
    <div className='tile is-ancestor'>

      {posts.map(post => (

        <div className='tile is-parent' key={post.id}>
          <section className='tile is-child notification box is-info'>
            
            <Link to={`/post/${post.id}`}>
              <h2 className='title is-4'>{post.title}</h2>
            </Link>
                  post id: {post.id}
            {/*
            <p className='content'>{post.content.substring(0, 300)}...</p>
            */}

            {!!post.content && (<p className='content'>{post.content.substring(0, 300)}...</p>)}

            <button type="button" onClick={()=>handleRemove(post.id)}> Remove </button>

            <button type="button" onClick={()=>handleEdit(post.id)}> Edit </button>

          </section>
        </div>
      ))}
    </div>
  )
}
