import { DataStore } from '@aws-amplify/datastore'
import { Post } from './models'
import {useState, useEffect } from 'react'
import{useHistory} from 'react-router-dom'


const EditPostComponent =props =>{ 

    const [title, setTitle] = useState(props.title) // not working
    const [content, setContent] = useState(props.content)

    console.log ('from const title', title)

    useEffect(
        () => {
            setTitle(props.title); setContent(props.content)
        },[ props ]
    )
    //added useEffect so that when the form is submitted as it is it won't resulted in undefined entries. 

    console.log ('from const title after useEffect', title)

    let history = useHistory()

    const editSubmit = async e => {
        e.preventDefault()
        
        const original = await DataStore.query(Post, props.id)

        console.log ('original->', original)

        await DataStore.save(
          Post.copyOf(original, updated =>{
              updated.title = title
              updated.content = content
          })
        )
             
        history.push("/")
        console.log ({"title submitted is now": title,  "content submitted is now": content })
      }
  
    return(
        <div>

            <p>YOU ARE NOW EDITING</p>
                post id: {props.id}

            <form onSubmit={editSubmit}>

                <div className='field'>
                    <label className='label' htmlFor='title'>Title</label>
                    <input className='input' type='text' name='title' defaultValue={title || ''}  onChange={e => setTitle(e.target.value)} />
                </div>

                <div className='field'>
                    <label className='label'>Content</label>
                    <textarea className='textarea' type='text' name='content' defaultValue={content || ''}  onChange={e => setContent(e.target.value)} />
                </div>

                <input className='button is-link' type='submit' value='submit edit' />

            </form>

            <br/>

        </div>
    )
}
export default EditPostComponent;