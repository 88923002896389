import {Link} from 'react-router-dom'
//import {AmplifySignOut} from '@aws-amplify/ui-react'
//import NavSignOut from './NavSignOut'

export default function NavBar () {
  return (
    <nav className='navbar' role='navigation' aria-label='main navigation'>
      <div className='navbar-brand'>
      
        <Link to='/' className='navbar-item'>
          <h1 className='title'>Hugh's New Year Project</h1>
        </Link>
      
      </div>
      <div className='navbar-end'>
        <div className='navbar-item'>
          <div className='buttons'>
          
            <Link to='/new' className='button is-primary'>
              <strong>Create a Post</strong>
            </Link>

            {/*
            <div className='button is-primary'><NavSignOut></div>
            */}

          </div>
        </div>
      </div>
    </nav>)
}