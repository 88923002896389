import 'bulma/css/bulma.css'
import { Route} from 'react-router-dom'

import CreatePost from './CreatePost'
import PostContainer from './Post'
import NavBar from './NavBar'
import PostList from './PostList'
import Deleted from './Deleted'
import EditPost from './EditPost'

import { withAuthenticator, AmplifySignOut } from '@aws-amplify/ui-react'

function App () {
  return (
    <div className='section'>
      <div className='container'>

        
        <NavBar />
        
        <div className='section'>

          <Route path='/new'>
            <CreatePost />
          </Route>
          
          <Route path='/post/:id'>
            <PostContainer />
          </Route>

          <Route path='/deleted'>
            <Deleted />
          </Route>

          <Route path='/editpost/:id'>
            <EditPost/>
          </Route>

          <Route path='/' exact>
            <PostList />
          </Route>

          <AmplifySignOut/>
          
        </div>

       

      </div>
    </div>
  )
}

export default withAuthenticator(App);