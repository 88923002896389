import{useHistory} from 'react-router-dom'

const Deleted = () =>{

    let history = useHistory();

    const GoBack=()=>{        
        history.push("/");
    }

    return(
        <div className="message is-large">
            <div className="message-header" >      
            <p>Deleted!</p>
            <button className="button is-success" onClick={GoBack}> Go Back to List of Posts</button>
        </div>

        </div>
        
    )
}

export default Deleted;