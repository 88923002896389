import { useParams } from 'react-router-dom'
import { DataStore } from '@aws-amplify/datastore'
import { Post } from './models'
import { useEffect, useState } from 'react'
//import{useHistory} from 'react-router-dom'
import EditPostComponent from './EditPostComponent'


const EditPost =() =>{

    const {id} = useParams()

    const [post, setPost] = useState({})

    //const [title, setTitle] = useState('')
    //const [content, setContent] = useState('')

    //let history = useHistory()

    /*
    useEffect(() => {
        const getData = async () => {
    
          const pagePost = await DataStore.query(Post, c => c.id('eq', id))

          setPost(pagePost[0])

          console.log('pagePost[0]', pagePost[0])

        }
        getData()
      }, [id])
    */

    useEffect(() => {

        const getData = async () => {
    
          const pagePost = await DataStore.query(Post, c => c.id('eq', id))
        
          console.log('pagePost[0] from useEffect showing the original content prior to edit:', pagePost[0])

          setPost(pagePost[0])

        };
        getData()
      }, [id])
    
   
/*
    const editSubmit = async e => {
        e.preventDefault()
        
        const original = await DataStore.query(Post, id)

        console.log ('original->', original)

        await DataStore.save(
          Post.copyOf(original, updated =>{
              updated.title = title
              updated.content = content
          })
        )
             
        history.push("/")

        console.log ({"title submitted is": title,  "content submitted is": content })
      }

*/
  
    return(
        <div>

         <EditPostComponent id={id} title={post.title} content={post.content}/>

        </div>
    )
}
export default EditPost;