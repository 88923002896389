/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://75ba3qxppzaqzmxar2rqpiq3ya.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-sf3fksmf6veafjlu6ajv2vj2wm",
    "aws_cognito_identity_pool_id": "us-east-1:385f83e9-4a63-48e0-a442-ab02bfa1d4b5",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_7swwZlIc7",
    "aws_user_pools_web_client_id": "40ret91l0djimvsoe3m834ddqd",
    "oauth": {}
};


export default awsmobile;
